import React from 'react';
import './css/Main.css';
import logo from './img/Logo.png';

function Main() {
  return (
    <div className="main-container">
      <img src={logo} alt="Logo" className="logo" />
      <h1 className="fade-in-text">Crecer Juntos</h1>
      <h2>Contáctanos: contacto@biyularx.com</h2>
    </div>
  );
}

export default Main;
